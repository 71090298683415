<template>
  <div>
    <vs-table :data="users" search :stripe="tableStripe" class="table-accounts">
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <div class="flex flex-wrap-reverse items-center">
          <b-button id="analyseAll" class="btn-campaign-filter" variant="outline-secondary" @click="monitoringAll">Analizar todo</b-button>
          <b-button id="protectAll" class="btn-campaign-filter" variant="outline-secondary" @click="protectingAll">Proteger todo</b-button>
          <b-button id="rechargeAccountsTable" class="btn-campaign-filter" variant="outline-secondary" @click="updateAccounts">
            <feather-icon icon="RotateCwIcon" svgClasses="h-4 w-4" />
          </b-button>
        </div>
      </div>

      <template slot="thead">
        <vs-th class="th-prename"></vs-th>
        <vs-th class="th-name" sort-key='name'>Nombre</vs-th>
        <vs-th class="th-ncampaign">Campañas</vs-th>
        <vs-th class="th-monitoring" sort-key='monitoring'>Analizar</vs-th>
        <vs-th class="th-protecting" sort-key='protecting' id="protectingtitle">Proteger</vs-th>
        <vs-th class="th-default" id="viewtitle">&nbsp;&nbsp;
          <feather-icon icon="EyeIcon" size="3x" />&nbsp;&nbsp;&nbsp;
        </vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody class="dataContainer">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.name" class='td-name'>
              <span v-if="tr.name.startsWith('Checking_ieHn73mNfRS')">La cuenta {{ tr.name.split('#')[1] }} se está validando <b-spinner variant="primary" type="grow"></b-spinner></span>
              <span v-else>
                {{ tr.name }}
              </span>
            </vs-td>
            <vs-td :data="tr.nCampaigns" class='td-ncampaign'>
              <span v-if="!tr.name.startsWith('Checking_ieHn73mNfRS')">{{ tr.nCampaigns }}</span>
            </vs-td>
            <vs-td :data="tr.monitoring" class='td-monitoring'>
              <vs-switch v-if="!tr.name.startsWith('Checking_ieHn73mNfRS')" :value="tr.monitoring" :id="'monitoring'+indextr" @click.stop="monSwClick(tr.monitoring,tr.id,tr.protecting)" />
              <b-popover :target="'monitoring'+indextr" triggers="hover" placement="top">
                Realiza un análisis del tráfico de los anuncios sin bloquear el fraude.
              </b-popover>
            </vs-td>
            <vs-td :data="tr.protecting" class='td-protecting'>
              <vs-switch v-if="!tr.name.startsWith('Checking_ieHn73mNfRS')" :value="tr.protecting" :id="'protecting'+indextr" @click.stop="proSwClick(tr.protecting,tr.id)" />
              <b-popover :target="'protecting'+indextr" triggers="hover" placement="top">
                Bloquea el tráfico fraudulento de los anuncios y protege la inversión.
              </b-popover>
            </vs-td>
            <vs-td class='td-default'>
              <span :id="'view'+indextr">
                <vs-radio v-if="!tr.name.startsWith('Checking_ieHn73mNfRS')" v-model="mainAccount" :vs-value="tr.id" @change="setDefaultAccount(tr)"></vs-radio>
              </span>
              <b-popover :target="'view'+indextr" triggers="hover" placement="top">
                Selecciona para ver y gestionar esta cuenta
              </b-popover>
            </vs-td>

            <template class="expand-user" slot="expand">
              <div class="con-expand-users w-full">
                <div class="con-btns-user flex items-center justify-between">
                  <vs-list class="campaigns-list">
                    <vs-list-item icon-pack="feather" icon="icon-link" :key="campa.id" v-for="campa in tr.campaigns" :title="campa.name">
                      <vs-chip v-if="campa.status === 'enabled'" color="success">Habilitada</vs-chip>
                      <vs-chip v-if="campa.status === 'paused'" color="warning">En pausa</vs-chip>
                      <vs-chip v-if="campa.status === 'stopped'" color="danger">Parada</vs-chip>

                      <vs-chip v-if="campa.network === 'd'" color="#996eab">Display</vs-chip>
                      <vs-chip v-if="campa.network === 'g'" color="#1388bf">Search</vs-chip>
                      <vs-chip v-if="campa.network === 's'" color="#3388bf">Smart</vs-chip>
                    </vs-list-item>
                  </vs-list>
                </div>
              </div>
            </template>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <b-modal id="payToProtect" ref="payToProtect" :visible="payProtect" centered>
      <template v-slot:modal-title>
        <img src="../assets/images/logo/clickdefense-logo-small-dark.png" width="40%">
      </template>
      <h3 class="text-center font-weight-bold">Protege tu inversión</h3>
      <p class="my-4 text-center">
        Aumenta el retorno de la inversión en tus anuncios eliminando los clics fraudulentos.
        <br>
        Para bloquear el tráfico fraudulento de tu cuenta debes ser usuario del modo protección.
        <br>
        Si no lo haces, podrás seguir analizando tus anuncios pero no mejorará tu conversión.
      </p>
      <template v-slot:modal-footer>
        <b-button size="sm" variant="outline-light" class="startButton" @click="goProtectPayment()">
          <router-link to="/payment" class="text-decoration-none text-white font-weight-bold">Mejorar a modo protección</router-link>
        </b-button>
      </template>
    </b-modal>
    <b-modal id="expireCreditCard" ref="expireCreditCard" :visible="expireCard" centered>
      <template v-slot:modal-title>
        <img src="../assets/images/logo/clickdefense-logo-small-dark.png" width="40%">
      </template>
      <h3 class="text-center font-weight-bold">¡Ops! Tu tarjeta no es válida</h3>
      <p class="my-4 text-center">
        Para seguir protegiendo tus anuncios debes actualizar tu tarjeta.
        Si no lo haces, tus anuncios se seguirán analizando pero no estarán protegidos.
      </p>
      <template v-slot:modal-footer>
        <b-button size="sm" variant="outline-light" class="startButton">
          <router-link to="/payment" class="text-decoration-none text-white font-weight-bold">Actualizar tarjeta</router-link>
        </b-button>
      </template>
    </b-modal>
    <b-modal id="newUserModal" ref="newUserModal" :visible="tourEnabled" centered size="lg" no-close-on-backdrop hide-header-close>
      <template v-slot:modal-title>
        <img src="../assets/images/logo/clickdefense-logo-small-dark.png" width="40%">
      </template>
      <div>
        <form-wizard ref="initialConfigSteps" color="rgba(var(--vs-primary), 1)" errorColor="rgba(var(--vs-danger), 1)" :title="null" :subtitle="null" finishButtonText="Finalizar" @on-complete="formOnComplete">
          <!-- Initial Config Step  -->
          <tab-content title="Análisis de Tráfico" class="mb-5" icon="feather icon-search">
            <div v-if="!preStepTwo">
              <div class="text-center">
                <h2>¡Bienvenido a ClickDefense!</h2>
                <h2 class="mb-6"><strong>Nos alegra tenerte entre nosotros.</strong></h2>
              </div>
              <div class="text-center-modal" v-if="!oneAccUser">
                <p class="text-center">Empieza <strong>activando el análisis gratuito</strong> de tráfico en las cuentas que prefieras.</p>
                <p><strong>Podrás averiguar:</strong></p>
                <p>Cuántos <strong>clics fraudulentos</strong> tienes y cuánto <strong>dinero has desperdiciado</strong> en ellos.</p>
              </div>
              <div v-else class="text-center-modal">
                <h5>Empieza <strong>activando el análisis gratuito</strong> de tráfico en tu cuenta {{users[0].name}}.</h5>
                <p><br><strong>Podrás averiguar:</strong></p>
                <p>Cuántos <strong>clics fraudulentos</strong> tienes y cuánto <strong>dinero has desperdiciado</strong> en ellos.</p>
              </div>
              <div>
                <vs-table :data="users" :stripe="tableStripe" class="table-modal-analiseOnly">
                  <template slot="thead">
                    <vs-th class="th-nameAnalyse" sort-key='name'>Cuenta</vs-th>
                    <vs-th class="th-monitoringModal" sort-key='monitoring'>Analizar</vs-th>
                  </template>

                  <template slot-scope="{ data }">
                    <tbody class="dataContainer">
                      <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td :data="tr.name" class='td-nameAnalyse'>
                          <span>
                            {{ tr.name }}
                          </span>
                        </vs-td>
                        <vs-td :data="tr.monitoring" class='td-monitoringModal'>
                          <vs-switch :value="tr.monitoring" :id="'monitoring'+indextr" @click.stop="monitoringOne(tr.monitoring,tr.id,tr.protecting)" />
                        </vs-td>
                      </vs-tr>
                    </tbody>
                  </template>
                </vs-table>
                <div class="btn-campaign-filter-container">
                  <b-button class="btn-campaign-filter" variant="outline-secondary" @click="monitoringAllModal">Analizar todas</b-button>
                </div>
                <span v-if="!stepOneComplete" class="analyseError">*Analiza al menos una cuenta para poder continuar</span>
              </div>
            </div>
            <div v-else>
              <div class="text-center-modal" v-if="!oneAccUser">
                <h5>Has iniciado un análisis gratuito de la calidad del tráfico en:</h5>
                <ul v-for="acc of users" :key="acc.name">
                  <li v-if="acc.monitoring===true">
                    {{acc.name}}
                  </li>
                </ul>
                <p>En los próximos días podrás ir viendo los resultados en las pestañas de Tráfico y Estadísticas.</p>
              </div>
              <div v-else class="text-center-modal">
                <h5>Has iniciado un análisis gratuito de la calidad del tráfico en {{users[0].name}}.</h5>
                <p>En los próximos días podrás ir viendo los resultados en las pestañas de Tráfico y Estadísticas.</p>
              </div>
              <p class="remember"><span class="rememberText">RECUERDA:</span> El modo análisis es <strong>completamente GRATIS</strong> por tiempo ilimitado.</p>
            </div>
          </tab-content>
          <!-- Initial Config Step 2 -->
          <tab-content title="Reglas de Negocio" class="mb-5" icon="feather icon-filter">
            <div v-if="!preStepThree">
              <div class="text-center">
                <h2>Ponle límite a tu coste de adquisición de clientes.</h2>
                <h5>Las reglas de negocio <strong>limitan los clics</strong> que puede hacer cada usuario en <strong>tus anuncios</strong>.</h5>
                <p class="text-center-modal" v-if="oneAccUser">Introduce la regla que quieras tener en cuenta para el análisis:</p>
                <p class="text-center-modal" v-else>Introduce las reglas que quieras tener en cuenta para el análisis:</p>
                <div>
                  <vs-table :data="rulesModal" :stripe="tableStripe" class="table-modal-rules">
                    <template slot="thead">
                      <vs-th class="th-nameRule" sort-key='name'>Cuenta</vs-th>
                      <vs-th class="th-limitModal" sort-key='limit'>Permitir hasta</vs-th>
                      <vs-th class="th-timeModal" sort-key='timeUnit'>Tiempo</vs-th>
                    </template>

                    <template slot-scope="{ data }">
                      <tbody class="dataContainer">
                        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                          <vs-td :data="tr.name" class='td-nameRule'>
                            <span>
                              {{ tr.name }}
                            </span>
                          </vs-td>
                          <vs-td :data="tr.limit" class='td-limitModal'>
                            <div class="rulesDataCon">
                              <b-form-input class="numberCont" @change="isRuleComplete" :type="'number'" min="1" value="1" v-model="tr.limit"></b-form-input> <span>clic </span>
                            </div>
                          </vs-td>
                          <vs-td :data="tr.time" class='td-timeModal'>
                            <div class="rulesDataCon">
                              <span class="rule-txt">en </span>
                              <b-form-input class="numberCont" @change="isRuleComplete" :type="'number'" min="1" value="24" v-model="tr.time"></b-form-input>
                              <vs-select class="selectCont" @change="isRuleComplete" value="hours" v-model="tr.timeUnit">
                                <vs-select-item :key="newindex" :value="timeitem.type" :text="timeitem.name" v-for="(timeitem,newindex) in timeType" />
                              </vs-select>
                            </div>
                          </vs-td>
                        </vs-tr>
                      </tbody>
                    </template>
                  </vs-table>
                  <div v-if="errorRule" class="analyseError">
                    <p>*Debes añadir al menos una regla en alguna cuenta.</p>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="text-center-modal" v-if="!oneAccUser">
                <h5>Has añadido una regla de negocio para el análisis en:</h5>
                <ul v-for="ruleAcc of rulesModal" :key="ruleAcc.name">
                  <li v-if="ruleAcc.limit!==''">
                    {{ruleAcc.name}}
                  </li>
                </ul>
                <p>Puedes modificar las reglas o añadir nuevas en la pestaña Reglas de Negocio.</p>
              </div>
              <div v-else class="text-center-modal">
                <h5>Has añadido una regla de negocio para el análisis en {{rulesModal[0].name}}.</h5>
                <p>Puedes modificar las reglas o añadir nuevas en la pestaña Reglas de Negocio.</p>
              </div>
              <p class="remember"><span class="rememberText">RECUERDA:</span> La regla solo servirá para el análisis y si activas el <strong>modo protección</strong> limitará los clics de manera efectiva.</p>
            </div>
          </tab-content>
          <!-- Initial Config Step 3 -->
          <tab-content title="Código de Seguimiento" class="mb-5" icon="feather icon-code">
            <div class="text-center-modal">
              <h2>Instala el código de seguimiento:</h2>
              <p v-if="oneAccUser">Para <strong>multiplicar el rendimiento en tus anuncios</strong> del algoritmo, te recomendamos que instales el código de seguimiento en cada página del dominio que tenga anuncios vinculados.</p>
              <p v-else>Para <strong>multiplicar el rendimiento en tus anuncios</strong> del algoritmo, te recomendamos que instales el código de seguimiento en cada página de los dominios que tengan anuncios vinculados.</p>
              <p>Puedes hacerlo a través de las siguientes opciones:</p>
              <div class="accordion" role="tablist">
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordionModal-1 class="paintAccordion">Google Tag Manager (Recomendado)</b-button>
                  </b-card-header>
                  <b-collapse id="accordionModal-1" visible accordion="accordionModal" role="tabpanel">
                    <b-card-body>
                      <b-card-text>Accede a nuestro manual a través del siguiente enlace:</b-card-text>
                      <b-card-text><a href="https://www.clickdefense.io/gtm-manual.html" target="_blank">https://www.clickdefense.io/gtm-manual.html</a></b-card-text>
                      <b-card-text>Sigue los pasos para configurar el pixel.</b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordionModal-4 class="paintAccordion">Otras opciones</b-button>
                  </b-card-header>
                  <b-collapse id="accordionModal-4" accordion="accordionModal" role="tabpanel">
                    <b-card-body>
                      <b-card-text>
                        Para instalar el pixel a través de otras opciones, pongase en contacto con soporte@clickdefense.io.
                        ¡Muchas gracias!
                      </b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <p class="remember"><span class="rememberText">RECUERDA:</span> Este paso es opcional y puedes completarlo mas adelante.</p>
              </div>
            </div>
          </tab-content>
          <!-- Steps Buttons -->
          <template slot="footer" slot-scope="props">
            <div class="wizard-footer-right">
              <span role="button">
                <div v-if="props.activeTabIndex === 0">
                  <button v-if="preStepTwo" @click="goStep1()" class="wizard-btn" style="background-color:rgba(var(--vs-primary), 1); border-color:rgba(var(--vs-primary), 1); color: white;">Continuar</button>
                  <button v-else @click="goStepOne1()" :disabled="!stepOneComplete" class="wizard-btn" style="background-color:rgba(var(--vs-primary), 1); border-color:rgba(var(--vs-primary), 1); color: white;">Continuar</button>
                </div>
                <div v-else-if="props.activeTabIndex === 1">
                  <button v-if="preStepThree" @click="goStep2()" class="wizard-btn" style="background-color:rgba(var(--vs-primary), 1); border-color:rgba(var(--vs-primary), 1); color: white;">Continuar</button>
                  <button v-else @click="goStepTwo1()" class="wizard-btn" style="background-color:rgba(var(--vs-primary), 1); border-color:rgba(var(--vs-primary), 1); color: white;">Continuar</button>
                </div>
                <button v-else-if="props.isLastStep" @click="endTour()" class="wizard-btn finish-button" style="background-color:rgba(var(--vs-primary), 1); border-color:rgba(var(--vs-primary), 1); color: white;">Terminar</button>
              </span>
            </div>
          </template>
        </form-wizard>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Amplify from "aws-amplify"
import { EventBus } from '@/event-bus'
import utils from '@/api/utils.js'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  data() {
    return {
      users: [],
      tableStripe: true,
      mainAccount: "",
      monitoringSw: [],
      protectingSw: [],
      addNewAccountSidebar: false,
      tourEnabled: false,
      oneAccUser: false,
      isComplete: false,
      stepOneComplete: false,
      errorStep2: false,
      payProtect: false,
      expireCard: false,
      creditCardValid: false,
      protectingFlag: {
        id: 0,
        pay: false,
      },
      integrationStep2: false,
      accUser: "",
      limitClick: "",
      timeNum: "",
      timeNumUnit: 'minutes',
      timeType: [{ type: 'minutes', name: 'minutos' }, { type: 'hours', name: 'horas' }, { type: 'days', name: 'días' }, { type: 'years', name: 'año' }],
      errorRule: false,
      id: '',
      rulesModal: [],
      preStepTwo: false,
      preStepThree: false
    }
  },
  methods: {
    closeCardModal() {
      this.expireCard = false
      this.$refs['expireCreditCard'].hide()
    },
    async initialGetUserData(dataUserId) {
      await this.$initMainData(dataUserId)
      this.users = this.$store.state.google.accounts
      this.mainAccount = this.$store.state.user.mainAccount
      this.updateSwitches()
      if (this.$store.state.user.tourEnabled) {
        this.tourEnabled = true
      }
      // FIN Get user
    },
    endTour() {
      const params = { tourEnabled: false }
      Amplify.Auth.currentSession()
        .then(apiSession => {
          this.$axios
            .put(`/user/${this.$store.state.user.id}`, params, {
              headers: {
                Authorization: "Bearer " + apiSession.idToken.jwtToken
              }
            })
        })
      this.$store.dispatch('updateTourEnabled', false)
      this.tourEnabled = false
      this.$refs['newUserModal'].hide()
    },
    changeAccount(account) {
      this.$store.dispatch('updateMainAccount', {
        id: account.id,
        name: account.name
      })
      EventBus.$emit('main-account-change', account.id)
      EventBus.$emit('change-account-rules', account.id)
    },
    monitoringAll() {
      this.monitoringSw.forEach(moni => {
        moni.value = true
      })
      this.$store.dispatch("setAllAccountsMonitoring", true)
      const googleAccount = this.$store.state.google.accounts
      for (let i = 0; i < googleAccount.length; i++) {
        const monParams = { monitoring: true, gAccount: googleAccount[i].id }
        Amplify.Auth.currentSession()
          .then(apiSession => {
            this.$axios
              .post("/useraction", monParams, {
                headers: {
                  Authorization: "Bearer " + apiSession.idToken.jwtToken
                }
              })
          })
        this.$gtm.push({ event: 'Analizar_Todas', userId: this.$store.state.user.id })
      }
      EventBus.$emit('update-monitoringAll', this.users)
      this.$store.dispatch('updateIntegrationStep', true)
    },
    protectingAll() {
      if (this.$acl.get[0] === 'user') {
        this.protectingFlag.id = 'all'
        this.$store.dispatch('updateProtectingFlag', this.protectingFlag)
        this.payProtect = true
        this.$refs['payToProtect'].show()
      } else {
        if (!this.creditCardValid) {
          this.expireCard = true
          this.$refs['expireCreditCard'].show()
        } else {
          this.protectingSw.forEach(prot => {
            prot.value = true
          })
          this.$gtm.push({ event: 'Proteger_Todas', userId: this.$store.state.user.id })
          this.$store.dispatch("setAllAccountsProtecting", true)
          const googleAccount = this.$store.state.google.accounts
          for (let i = 0; i < googleAccount.length; i++) {
            const proParams = { protecting: true, gAccount: googleAccount[i].id }
            Amplify.Auth.currentSession()
              .then(apiSession => {
                this.$axios
                  .post("/useraction", proParams, {
                    headers: {
                      Authorization: "Bearer " + apiSession.idToken.jwtToken
                    }
                  })
              })
          }
          this.monitoringAll()
        }
      }
    },
    setDefaultAccount(data) {
      this.changeAccount(data)
      const mainAccountParams = {
        mainAccount: this.mainAccount
      }
      this.$store.dispatch("setDefaultAccount", mainAccountParams)
      if (!this.$store.state.user.isAdmin) {
        Amplify.Auth.currentSession().then(apiSession => {
          // INI Set user mainAccount
          const paramsUser = {
            mainAccount: data.id,
            mainAccountName: data.name
          }
          this.$axios.put('/user/0', paramsUser, {
            headers: { 'Authorization': 'Bearer ' + apiSession.idToken.jwtToken }
          })
          // FIN Set user mainAccount
        })
      }
    },
    monSwClick(data, dataid, protect) {
      const monParams = {
        gAccount: dataid,
        monitoring: !data
      }
      if (data) {
        if (protect) {
          this.proSwClick(protect, dataid, data)
        }
      }
      this.$store.dispatch("updateAccountMonitoring", monParams)
      Amplify.Auth.currentSession()
        .then(apiSession => {
          this.$axios
            .post("/useraction", monParams, {
              headers: {
                Authorization: "Bearer " + apiSession.idToken.jwtToken
              }
            }).then(() => {
              this.$gtm.push({ event: 'Analizar_Cuenta', userId: this.$store.state.user.id, accState: !data })
            })
        })
      EventBus.$emit('update-monitoring', this.users)
      let stepState = this.areMonitoringAcc()
      this.$store.dispatch('updateIntegrationStep', stepState)
      EventBus.$emit('update-integrationStep', stepState)

    },
    proSwClick(data, dataid, monitoring) {
      if (this.$acl.get[0] === 'user') {
        this.protectingFlag.id = dataid
        this.$store.dispatch('updateProtectingFlag', this.protectingFlag)
        this.payProtect = true
        this.$refs['payToProtect'].show()
      } else {
        if (!this.creditCardValid) {
          if (data) {
            const proParams = {
              gAccount: dataid,
              protecting: !data
            }
            this.$store.dispatch("updateAccountProtecting", proParams)
            Amplify.Auth.currentSession()
              .then(apiSession => {
                this.$axios
                  .post("/useraction", proParams, {
                    headers: {
                      Authorization: "Bearer " + apiSession.idToken.jwtToken
                    }
                  })
              })
          }
          this.expireCard = true
          this.$refs['expireCreditCard'].show()
        } else {
          const proParams = {
            gAccount: dataid,
            protecting: !data
          }
          if (!data) {
            if (!monitoring) {
              this.monSwClick(monitoring, dataid, data)
            }
          }
          this.$store.dispatch("updateAccountProtecting", proParams)
          Amplify.Auth.currentSession()
            .then(apiSession => {
              this.$axios
                .post("/useraction", proParams, {
                  headers: {
                    Authorization: "Bearer " + apiSession.idToken.jwtToken
                  }
                })
            })
          this.$gtm.push({ event: 'Proteger_Cuenta', userId: this.$store.state.user.id, accState: !data })
        }

      }
    },
    updateAccounts() {
      this.$vs.loading()
      Amplify.Auth.currentSession().then(apiSession => {
        // INI Get user
        this.$axios.get(`/accounts`, {
          headers: { 'Authorization': 'Bearer ' + apiSession.idToken.jwtToken }
        }).then(response => {
          this.composeAccounts(response.data)
          this.updateSwitches()
          this.$vs.loading.close()
        })
        // FIN Get user
      })
    },
    composeAccounts(dataAccounts) {
      let accountsArray = []
      for (const accountRaw in dataAccounts) {
        const miniAccount = dataAccounts[accountRaw]
        let account = {
          id: miniAccount.accountId,
          mainAccount: false,
          monitoring: miniAccount['monitoring'] || false,
          nCampaigns: 1,
          name: miniAccount['acc.name'] || 'Unnamed',
          protecting: miniAccount['protecting'] || false,
          campaigns: []
        }
        for (const campa of miniAccount.campaigns) {
          let campaign = {
            id: campa['gCampaign'] || '',
            name: campa['cam.name'] || 'Unnamed',
            network: 'g',
            status: campa['cam.status'] || 'disabled'
          }
          if (campa['cam.network'] === 'display') {
            campaign.network = 'd'
          }
          account.campaigns.push(campaign)
        }
        account.nCampaigns = miniAccount.campaigns.length
        if (miniAccount.accountId === this.$store.state.user.mainAccount) {
          account.mainAccount = true
          this.mainAccount = miniAccount.accountId
        }
        accountsArray.push(account)
      }
      accountsArray.sort(function (a, b) { return a.monitoring - b.monitoring })
      accountsArray.sort(function (a, b) { return a.protecting - b.protecting })
      accountsArray.reverse()
      if (accountsArray.length === 1) {
        this.oneAccUser = true
      } else {
        this.oneAccUser = false
      }
      this.$store.dispatch('updateUserAccounts', accountsArray)
      this.users = accountsArray
    },
    updateSwitches() {
      this.monitoringSw = []
      this.protectingSw = []
      let isOnlyOne = 0
      for (let i = 0; i < this.users.length; i++) {
        this.monitoringSw.push({
          id: this.users[i].id,
          value: this.users[i].monitoring
        })
        this.protectingSw.push({
          id: this.users[i].id,
          value: this.users[i].protecting
        })
        isOnlyOne++
      }
      if (isOnlyOne === 1) {
        this.oneAccUser = true
      }
    },
    areMonitoringAcc() {
      let analyseAcc = 0
      for (let acc of this.users) {
        if (acc.monitoring) {
          analyseAcc++
        }
      }
      if (analyseAcc > 0) {
        return true
      } else {
        return false
      }
    },
    // ----- Initial configure user modal functions -----
    goStep1() {
      this.composeModalRules(this.$store.state.google.accounts)
      this.$gtm.push({ event: 'Modal_Paso1_Completado', userId: this.$store.state.user.id })
      this.$refs.initialConfigSteps.changeTab(0, 1)
    },
    goStepOne1() {
      this.preStepTwo = true
      this.$gtm.push({ event: 'Modal_Paso1-1_Completado', userId: this.$store.state.user.id })
    },
    goStepTwo1() {
      let error = 0
      for (let ruleAcc of this.rulesModal) {
        if (ruleAcc.limit === "") {
          error++
        } else {
          this.addNewRule(ruleAcc)
        }
      }
      if (error !== this.rulesModal.length) {
        this.$gtm.push({ event: 'Modal_Paso2-1_Completado', userId: this.$store.state.user.id })
        this.errorRule = false
        this.preStepThree = true
      } else {
        this.errorRule = true
      }
    },
    goStep2() {
      this.$gtm.push({ event: 'Modal_Paso2_Completado', userId: this.$store.state.user.id })
      this.$refs.initialConfigSteps.changeTab(0, 2)
      this.stepTwoComplete = true
    },
    formOnComplete() {
      this.$gtm.push({ event: 'Modal_Terminar', userId: this.$store.state.user.id })
      this.endTour()
    },
    monitoringAllModal() {
      this.$gtm.push({ event: 'Modal_Analiza_Todas', userId: this.$store.state.user.id })
      this.stepOneComplete = true
      this.monitoringAll()
    },
    monitoringOne(data, dataid, protect) {
      this.$gtm.push({ event: 'Analiza_Cuenta_Modal', userId: this.$store.state.user.id, accState: !data })
      this.monSwClick(data, dataid, protect)
      let analyseAcc = 0
      for (let acc of this.users) {
        if (acc.monitoring) {
          analyseAcc++
        }
      }
      if (analyseAcc > 0) {
        this.stepOneComplete = true
      } else {
        this.stepOneComplete = false
      }
    },
    isRuleComplete() {
      let counterFails = 0
      if (this.accUser === "" && !this.oneAccUser) {
        counterFails++
      } else if (this.limitClick === "") {
        counterFails++
      } else if (this.timeNum === "") {
        counterFails++
      } else if (this.timeNumUnit === "") {
        counterFails++
      }
      if (counterFails !== 0) {
        this.stepTwoComplete = false
      } else {
        this.stepTwoComplete = true
      }
    },
    addNewRule(rule) {
      Amplify.Auth.currentSession().then(apiSession => {
        // INI Create user rule
        const paramsUserRul = {
          name: 'Permitir hasta',
          limit: rule.limit,
          type: 'click',
          time: rule.time,
          timeUnit: rule.timeUnit,
          delAfter: 1000,
          delAfterUnit: 'months',
          active: true,
          ruleType: 'add',
          accountId: rule.id
        }
        if (this.id !== '') {
          paramsUserRul.id = this.id
        } else {
          paramsUserRul.id = this.$uuid.v4()
        }
        this.$axios.post('/userrules/0', paramsUserRul, {
          headers: { 'Authorization': 'Bearer ' + apiSession.idToken.jwtToken }
        }).then(response => {
          if (response.status === 200) {
            this.$gtm.push({ event: 'Modal_Regla_Negocio', userId: this.$store.state.user.id })
            if (this.oneAccUser || rule.id === this.mainAccount) {
              this.$store.dispatch('updateUserRule', paramsUserRul)
            }
          }
        })
        // FIN Create user rule
      })
    },
    composeModalRules(accounts) {
      let rulesArray = []
      for (const account of accounts) {
        const rule = {
          id: account.id,
          name: account.name,
          limit: '',
          time: 24,
          timeUnit: 'hours',
        }
        if (account.monitoring) {
          rulesArray.push(rule)
        }
      }
      this.rulesModal = rulesArray
    },
    goProtectPayment() {
      this.$gtm.push({ event: 'Alerta_Subscripcion_OK', userId: this.$store.state.user.id })
    }
  },
  mounted() {
    Amplify.Auth.currentSession().then(apiSession => {
      const paramsCheckAdmin = apiSession.accessToken.payload['cognito:groups']
      this.$store.dispatch('updateAdmin', utils.isAdminUser(paramsCheckAdmin))
      if (this.$store.state.user.isAdmin && this.$store.state.user.mainAccount !== '') {
        this.initialGetUserData(this.$store.state.user.id)
        this.$acl.change('admin')
      } else if (this.$store.state.user.isAdmin && this.$store.state.user.mainAccount === '') {
        this.$vs.loading.close()
        this.$acl.change('admin')
        this.$router.push('/admin-page')
      } else {
        this.initialGetUserData('0')
        this.$vs.loading.close()
      }
    })
    EventBus.$on('user-creditCardValid', (creditCardValid) => {
      this.creditCardValid = creditCardValid
    })
    EventBus.$on('protectAll', () => {
      this.creditCardValid = true
      this.$store.dispatch('updateCreditCardValid', true)
      this.protectingFlag.pay = false
      this.protectingFlag.id = 0
      this.$store.dispatch('updateProtectingFlag', this.protectingFlag)
      this.protectingAll()
    })
  },
  components: {
    FormWizard,
    TabContent
  }
}
</script>

<style scoped>
.th-prename {
  width: 24px;
}
.th-name,
.td-name {
  width: 50%;
  word-wrap: break-word;
}
.th-ncampaign,
.td-ncampaign {
  width: 14%;
  word-wrap: break-word;
}
.th-monitoring,
.td-monitoring {
  width: 13%;
  word-wrap: break-word;
}
.th-monitoringModal,
.td-monitoringModal {
  word-wrap: break-word;
  width: 50%;
}
.th-protecting,
.td-protecting {
  width: 13%;
  word-wrap: break-word;
}
.th-default,
.td-default {
  width: 10%;
  word-wrap: break-word;
}
.th-name {
  padding: 10px;
}
.th-ncampaign {
  padding: 10px;
}
.th-monitoring {
  padding: 10px;
}
.th-protecting {
  padding: 10px;
}
.th-default {
  padding: 10px;
}
.iconColor {
  color: #7367f0;
}
.btn-campaign-filter-container {
  justify-content: center;
  display: flex;
  margin-bottom: 1%;
}
.analyseError {
  color: firebrick;
  font-size: 10px;
}
</style>
<style>
.table-accounts .vs-table--content .vs-table--tbody .vs-table--tbody-table {
  font-size: 1.5rem;
}
.table-accounts
  .vs-table--content
  .vs-table--tbody
  .vs-table--tbody-table
  .vs-table--thead
  th {
  font-size: 1.2rem;
}
.table-accounts
  .vs-table--content
  .vs-table--tbody
  .vs-table--tbody-table
  .vs-table--thead
  tr {
  border-bottom: 2px solid #686868;
  height: 1rem;
}
.table-accounts .vs-table--content .vs-table--tbody .vs-table--tbody-table tr {
  height: 7rem;
}
.campaigns-list .vs-list--item .list-titles .vs-list--title {
  font-weight: normal;
  color: #424242;
  font-size: 1.15rem;
}
.th-ncampaign > div {
  justify-content: center;
}
.td-ncampaign {
  text-align: center;
}
.th-monitoring > div {
  justify-content: center;
}
.td-monitoring > span,
.td-protecting > span,
.td-default > span {
  justify-content: center;
  display: flex;
}
.th-protecting > div {
  justify-content: center;
}
.th-default > div {
  justify-content: center;
}
.table-accounts > div > div > table > thead,
.table-accounts > div > div > table > tbody {
  overflow: hidden;
  overflow-y: scroll;
  display: block;
}
.table-accounts > div > div > table > tbody {
  max-height: 65vh;
  min-height: 180px;
}
@media (max-width: 1500px) {
  .td-ncampaign {
    text-align: center;
  }
  .td-monitoring > span,
  .td-protecting > span,
  .td-default > span {
    justify-content: center;
  }
}
@media (max-width: 1700px) and (min-width: 1500px) {
  .td-monitoring > span,
  .td-protecting > span,
  .td-default > span {
    justify-content: space-around;
  }
}
#payToProtect___BV_modal_footer_ {
  justify-content: center !important;
  background-color: #fff;
  color: gray;
}
#payToProtect___BV_modal_header_ {
  background-color: #2f1b7a;
  color: white;
}
#payToProtect___BV_modal_header_ > button {
  color: white;
}
#payToProtect___BV_modal_body_ {
  background-color: #fff;
  color: gray;
}
#payToProtect___BV_modal_title_ {
  color: gray;
}
#payToProtect___BV_modal_outer_ {
  z-index: 55000 !important;
}
.startButton {
  background-color: #2f1b7a;
}
.startButton:hover {
  background-color: #6556d7;
}
.startButton:hover a {
  color: #212529 !important;
}
#expireCreditCard___BV_modal_footer_ {
  justify-content: center !important;
  background-color: #fff;
  color: gray;
}
#expireCreditCard___BV_modal_header_ {
  background-color: #2f1b7a;
  color: white;
}
#expireCreditCard___BV_modal_header_ > button {
  color: white;
}
#expireCreditCard___BV_modal_body_ {
  background-color: #fff;
  color: gray;
}
#expireCreditCard___BV_modal_title_ {
  color: gray;
}
#expireCreditCard___BV_modal_outer_ {
  z-index: 55000 !important;
}
.dataContainer {
  min-height: 125px;
}
#newUserModal___BV_modal_footer_ {
  justify-content: center !important;
  background-color: #fff;
  color: gray;
  display: none;
}
#newUserModal___BV_modal_header_ {
  background-color: #2f1b7a;
  color: white;
}
#newUserModal___BV_modal_header_ > button {
  color: white;
}
#newUserModal___BV_modal_body_ {
  background-color: #fff;
  color: gray;
  padding: 0;
}
#newUserModal___BV_modal_title_ {
  color: gray;
}
#newUserModal___BV_modal_outer_ {
  z-index: 55000 !important;
}
.numberCont {
  width: 60px;
}
.selectCont {
  width: 120px;
}
.paintAccordion {
  background: #2f1b7a;
}
.paintAccordion:hover {
  background: #4f3a9c;
}
.remember {
  color: green;
  margin-top: 5px;
  text-align: center;
  font-size: 1rem;
}
.rememberText {
  color: black;
  font-size: 0.7rem;
  font-weight: bold;
}
.th-nameRule > div {
  justify-content: center;
}
.th-nameAnalyse > div {
  justify-content: center;
}
.td-nameRule {
  text-align: center;
}
.th-limitModal > div {
  justify-content: center;
}
.td-limitModal > span,
.td-timeModal > span,
.td-default > span {
  justify-content: center;
  display: flex;
}
.th-timeModal > div {
  justify-content: center;
}
.th-default > div {
  justify-content: center;
}
.input-select-con,
.input-select {
  height: calc(1.5em + 0.75rem + 2px);
}
.rulesDataCon {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  width: 100%;
}
.text-center-modal {
  text-align: center;
  font-size: medium;
}
.text-center-modal > ul {
  list-style-type: unset;
}
.btn-campaign-filter {
  margin-left: 10px;
}
</style>
