var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-table",
        {
          staticClass: "table-accounts",
          attrs: { data: _vm.users, search: "", stripe: _vm.tableStripe },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var data = ref.data
                return [
                  _c(
                    "tbody",
                    { staticClass: "dataContainer" },
                    _vm._l(data, function(tr, indextr) {
                      return _c(
                        "vs-tr",
                        { key: indextr, attrs: { data: tr } },
                        [
                          _c(
                            "vs-td",
                            {
                              staticClass: "td-name",
                              attrs: { data: tr.name }
                            },
                            [
                              tr.name.startsWith("Checking_ieHn73mNfRS")
                                ? _c(
                                    "span",
                                    [
                                      _vm._v(
                                        "La cuenta " +
                                          _vm._s(tr.name.split("#")[1]) +
                                          " se está validando "
                                      ),
                                      _c("b-spinner", {
                                        attrs: {
                                          variant: "primary",
                                          type: "grow"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _c("span", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(tr.name) +
                                        "\n            "
                                    )
                                  ])
                            ]
                          ),
                          _c(
                            "vs-td",
                            {
                              staticClass: "td-ncampaign",
                              attrs: { data: tr.nCampaigns }
                            },
                            [
                              !tr.name.startsWith("Checking_ieHn73mNfRS")
                                ? _c("span", [_vm._v(_vm._s(tr.nCampaigns))])
                                : _vm._e()
                            ]
                          ),
                          _c(
                            "vs-td",
                            {
                              staticClass: "td-monitoring",
                              attrs: { data: tr.monitoring }
                            },
                            [
                              !tr.name.startsWith("Checking_ieHn73mNfRS")
                                ? _c("vs-switch", {
                                    attrs: {
                                      value: tr.monitoring,
                                      id: "monitoring" + indextr
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.monSwClick(
                                          tr.monitoring,
                                          tr.id,
                                          tr.protecting
                                        )
                                      }
                                    }
                                  })
                                : _vm._e(),
                              _c(
                                "b-popover",
                                {
                                  attrs: {
                                    target: "monitoring" + indextr,
                                    triggers: "hover",
                                    placement: "top"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              Realiza un análisis del tráfico de los anuncios sin bloquear el fraude.\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "vs-td",
                            {
                              staticClass: "td-protecting",
                              attrs: { data: tr.protecting }
                            },
                            [
                              !tr.name.startsWith("Checking_ieHn73mNfRS")
                                ? _c("vs-switch", {
                                    attrs: {
                                      value: tr.protecting,
                                      id: "protecting" + indextr
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.proSwClick(
                                          tr.protecting,
                                          tr.id
                                        )
                                      }
                                    }
                                  })
                                : _vm._e(),
                              _c(
                                "b-popover",
                                {
                                  attrs: {
                                    target: "protecting" + indextr,
                                    triggers: "hover",
                                    placement: "top"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              Bloquea el tráfico fraudulento de los anuncios y protege la inversión.\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "vs-td",
                            { staticClass: "td-default" },
                            [
                              _c(
                                "span",
                                { attrs: { id: "view" + indextr } },
                                [
                                  !tr.name.startsWith("Checking_ieHn73mNfRS")
                                    ? _c("vs-radio", {
                                        attrs: { "vs-value": tr.id },
                                        on: {
                                          change: function($event) {
                                            return _vm.setDefaultAccount(tr)
                                          }
                                        },
                                        model: {
                                          value: _vm.mainAccount,
                                          callback: function($$v) {
                                            _vm.mainAccount = $$v
                                          },
                                          expression: "mainAccount"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "b-popover",
                                {
                                  attrs: {
                                    target: "view" + indextr,
                                    triggers: "hover",
                                    placement: "top"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              Selecciona para ver y gestionar esta cuenta\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "template",
                            { staticClass: "expand-user", slot: "expand" },
                            [
                              _c(
                                "div",
                                { staticClass: "con-expand-users w-full" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "con-btns-user flex items-center justify-between"
                                    },
                                    [
                                      _c(
                                        "vs-list",
                                        { staticClass: "campaigns-list" },
                                        _vm._l(tr.campaigns, function(campa) {
                                          return _c(
                                            "vs-list-item",
                                            {
                                              key: campa.id,
                                              attrs: {
                                                "icon-pack": "feather",
                                                icon: "icon-link",
                                                title: campa.name
                                              }
                                            },
                                            [
                                              campa.status === "enabled"
                                                ? _c(
                                                    "vs-chip",
                                                    {
                                                      attrs: {
                                                        color: "success"
                                                      }
                                                    },
                                                    [_vm._v("Habilitada")]
                                                  )
                                                : _vm._e(),
                                              campa.status === "paused"
                                                ? _c(
                                                    "vs-chip",
                                                    {
                                                      attrs: {
                                                        color: "warning"
                                                      }
                                                    },
                                                    [_vm._v("En pausa")]
                                                  )
                                                : _vm._e(),
                                              campa.status === "stopped"
                                                ? _c(
                                                    "vs-chip",
                                                    {
                                                      attrs: { color: "danger" }
                                                    },
                                                    [_vm._v("Parada")]
                                                  )
                                                : _vm._e(),
                                              campa.network === "d"
                                                ? _c(
                                                    "vs-chip",
                                                    {
                                                      attrs: {
                                                        color: "#996eab"
                                                      }
                                                    },
                                                    [_vm._v("Display")]
                                                  )
                                                : _vm._e(),
                                              campa.network === "g"
                                                ? _c(
                                                    "vs-chip",
                                                    {
                                                      attrs: {
                                                        color: "#1388bf"
                                                      }
                                                    },
                                                    [_vm._v("Search")]
                                                  )
                                                : _vm._e(),
                                              campa.network === "s"
                                                ? _c(
                                                    "vs-chip",
                                                    {
                                                      attrs: {
                                                        color: "#3388bf"
                                                      }
                                                    },
                                                    [_vm._v("Smart")]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        2
                      )
                    }),
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "div",
            {
              staticClass:
                "flex flex-wrap-reverse items-center flex-grow justify-between",
              attrs: { slot: "header" },
              slot: "header"
            },
            [
              _c(
                "div",
                { staticClass: "flex flex-wrap-reverse items-center" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-campaign-filter",
                      attrs: { id: "analyseAll", variant: "outline-secondary" },
                      on: { click: _vm.monitoringAll }
                    },
                    [_vm._v("Analizar todo")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-campaign-filter",
                      attrs: { id: "protectAll", variant: "outline-secondary" },
                      on: { click: _vm.protectingAll }
                    },
                    [_vm._v("Proteger todo")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-campaign-filter",
                      attrs: {
                        id: "rechargeAccountsTable",
                        variant: "outline-secondary"
                      },
                      on: { click: _vm.updateAccounts }
                    },
                    [
                      _c("feather-icon", {
                        attrs: { icon: "RotateCwIcon", svgClasses: "h-4 w-4" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "template",
            { slot: "thead" },
            [
              _c("vs-th", { staticClass: "th-prename" }),
              _c(
                "vs-th",
                { staticClass: "th-name", attrs: { "sort-key": "name" } },
                [_vm._v("Nombre")]
              ),
              _c("vs-th", { staticClass: "th-ncampaign" }, [
                _vm._v("Campañas")
              ]),
              _c(
                "vs-th",
                {
                  staticClass: "th-monitoring",
                  attrs: { "sort-key": "monitoring" }
                },
                [_vm._v("Analizar")]
              ),
              _c(
                "vs-th",
                {
                  staticClass: "th-protecting",
                  attrs: { "sort-key": "protecting", id: "protectingtitle" }
                },
                [_vm._v("Proteger")]
              ),
              _c(
                "vs-th",
                { staticClass: "th-default", attrs: { id: "viewtitle" } },
                [
                  _vm._v("  \n        "),
                  _c("feather-icon", {
                    attrs: { icon: "EyeIcon", size: "3x" }
                  }),
                  _vm._v("   \n      ")
                ],
                1
              )
            ],
            1
          )
        ],
        2
      ),
      _c(
        "b-modal",
        {
          ref: "payToProtect",
          attrs: { id: "payToProtect", visible: _vm.payProtect, centered: "" },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function() {
                return [
                  _c("img", {
                    attrs: {
                      src: require("../assets/images/logo/clickdefense-logo-small-dark.png"),
                      width: "40%"
                    }
                  })
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-button",
                    {
                      staticClass: "startButton",
                      attrs: { size: "sm", variant: "outline-light" },
                      on: {
                        click: function($event) {
                          return _vm.goProtectPayment()
                        }
                      }
                    },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass:
                            "text-decoration-none text-white font-weight-bold",
                          attrs: { to: "/payment" }
                        },
                        [_vm._v("Mejorar a modo protección")]
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("h3", { staticClass: "text-center font-weight-bold" }, [
            _vm._v("Protege tu inversión")
          ]),
          _c("p", { staticClass: "my-4 text-center" }, [
            _vm._v(
              "\n      Aumenta el retorno de la inversión en tus anuncios eliminando los clics fraudulentos.\n      "
            ),
            _c("br"),
            _vm._v(
              "\n      Para bloquear el tráfico fraudulento de tu cuenta debes ser usuario del modo protección.\n      "
            ),
            _c("br"),
            _vm._v(
              "\n      Si no lo haces, podrás seguir analizando tus anuncios pero no mejorará tu conversión.\n    "
            )
          ])
        ]
      ),
      _c(
        "b-modal",
        {
          ref: "expireCreditCard",
          attrs: {
            id: "expireCreditCard",
            visible: _vm.expireCard,
            centered: ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function() {
                return [
                  _c("img", {
                    attrs: {
                      src: require("../assets/images/logo/clickdefense-logo-small-dark.png"),
                      width: "40%"
                    }
                  })
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-button",
                    {
                      staticClass: "startButton",
                      attrs: { size: "sm", variant: "outline-light" }
                    },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass:
                            "text-decoration-none text-white font-weight-bold",
                          attrs: { to: "/payment" }
                        },
                        [_vm._v("Actualizar tarjeta")]
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("h3", { staticClass: "text-center font-weight-bold" }, [
            _vm._v("¡Ops! Tu tarjeta no es válida")
          ]),
          _c("p", { staticClass: "my-4 text-center" }, [
            _vm._v(
              "\n      Para seguir protegiendo tus anuncios debes actualizar tu tarjeta.\n      Si no lo haces, tus anuncios se seguirán analizando pero no estarán protegidos.\n    "
            )
          ])
        ]
      ),
      _c(
        "b-modal",
        {
          ref: "newUserModal",
          attrs: {
            id: "newUserModal",
            visible: _vm.tourEnabled,
            centered: "",
            size: "lg",
            "no-close-on-backdrop": "",
            "hide-header-close": ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function() {
                return [
                  _c("img", {
                    attrs: {
                      src: require("../assets/images/logo/clickdefense-logo-small-dark.png"),
                      width: "40%"
                    }
                  })
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "div",
            [
              _c(
                "form-wizard",
                {
                  ref: "initialConfigSteps",
                  attrs: {
                    color: "rgba(var(--vs-primary), 1)",
                    errorColor: "rgba(var(--vs-danger), 1)",
                    title: null,
                    subtitle: null,
                    finishButtonText: "Finalizar"
                  },
                  on: { "on-complete": _vm.formOnComplete },
                  scopedSlots: _vm._u([
                    {
                      key: "footer",
                      fn: function(props) {
                        return [
                          _c("div", { staticClass: "wizard-footer-right" }, [
                            _c("span", { attrs: { role: "button" } }, [
                              props.activeTabIndex === 0
                                ? _c("div", [
                                    _vm.preStepTwo
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "wizard-btn",
                                            staticStyle: {
                                              "background-color":
                                                "rgba(var(--vs-primary), 1)",
                                              "border-color":
                                                "rgba(var(--vs-primary), 1)",
                                              color: "white"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.goStep1()
                                              }
                                            }
                                          },
                                          [_vm._v("Continuar")]
                                        )
                                      : _c(
                                          "button",
                                          {
                                            staticClass: "wizard-btn",
                                            staticStyle: {
                                              "background-color":
                                                "rgba(var(--vs-primary), 1)",
                                              "border-color":
                                                "rgba(var(--vs-primary), 1)",
                                              color: "white"
                                            },
                                            attrs: {
                                              disabled: !_vm.stepOneComplete
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.goStepOne1()
                                              }
                                            }
                                          },
                                          [_vm._v("Continuar")]
                                        )
                                  ])
                                : props.activeTabIndex === 1
                                ? _c("div", [
                                    _vm.preStepThree
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "wizard-btn",
                                            staticStyle: {
                                              "background-color":
                                                "rgba(var(--vs-primary), 1)",
                                              "border-color":
                                                "rgba(var(--vs-primary), 1)",
                                              color: "white"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.goStep2()
                                              }
                                            }
                                          },
                                          [_vm._v("Continuar")]
                                        )
                                      : _c(
                                          "button",
                                          {
                                            staticClass: "wizard-btn",
                                            staticStyle: {
                                              "background-color":
                                                "rgba(var(--vs-primary), 1)",
                                              "border-color":
                                                "rgba(var(--vs-primary), 1)",
                                              color: "white"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.goStepTwo1()
                                              }
                                            }
                                          },
                                          [_vm._v("Continuar")]
                                        )
                                  ])
                                : props.isLastStep
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "wizard-btn finish-button",
                                      staticStyle: {
                                        "background-color":
                                          "rgba(var(--vs-primary), 1)",
                                        "border-color":
                                          "rgba(var(--vs-primary), 1)",
                                        color: "white"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.endTour()
                                        }
                                      }
                                    },
                                    [_vm._v("Terminar")]
                                  )
                                : _vm._e()
                            ])
                          ])
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(
                    "tab-content",
                    {
                      staticClass: "mb-5",
                      attrs: {
                        title: "Análisis de Tráfico",
                        icon: "feather icon-search"
                      }
                    },
                    [
                      !_vm.preStepTwo
                        ? _c("div", [
                            _c("div", { staticClass: "text-center" }, [
                              _c("h2", [_vm._v("¡Bienvenido a ClickDefense!")]),
                              _c("h2", { staticClass: "mb-6" }, [
                                _c("strong", [
                                  _vm._v("Nos alegra tenerte entre nosotros.")
                                ])
                              ])
                            ]),
                            !_vm.oneAccUser
                              ? _c(
                                  "div",
                                  { staticClass: "text-center-modal" },
                                  [
                                    _c("p", { staticClass: "text-center" }, [
                                      _vm._v("Empieza "),
                                      _c("strong", [
                                        _vm._v("activando el análisis gratuito")
                                      ]),
                                      _vm._v(
                                        " de tráfico en las cuentas que prefieras."
                                      )
                                    ]),
                                    _c("p", [
                                      _c("strong", [
                                        _vm._v("Podrás averiguar:")
                                      ])
                                    ]),
                                    _c("p", [
                                      _vm._v("Cuántos "),
                                      _c("strong", [
                                        _vm._v("clics fraudulentos")
                                      ]),
                                      _vm._v(" tienes y cuánto "),
                                      _c("strong", [
                                        _vm._v("dinero has desperdiciado")
                                      ]),
                                      _vm._v(" en ellos.")
                                    ])
                                  ]
                                )
                              : _c(
                                  "div",
                                  { staticClass: "text-center-modal" },
                                  [
                                    _c("h5", [
                                      _vm._v("Empieza "),
                                      _c("strong", [
                                        _vm._v("activando el análisis gratuito")
                                      ]),
                                      _vm._v(
                                        " de tráfico en tu cuenta " +
                                          _vm._s(_vm.users[0].name) +
                                          "."
                                      )
                                    ]),
                                    _c("p", [
                                      _c("br"),
                                      _c("strong", [
                                        _vm._v("Podrás averiguar:")
                                      ])
                                    ]),
                                    _c("p", [
                                      _vm._v("Cuántos "),
                                      _c("strong", [
                                        _vm._v("clics fraudulentos")
                                      ]),
                                      _vm._v(" tienes y cuánto "),
                                      _c("strong", [
                                        _vm._v("dinero has desperdiciado")
                                      ]),
                                      _vm._v(" en ellos.")
                                    ])
                                  ]
                                ),
                            _c(
                              "div",
                              [
                                _c(
                                  "vs-table",
                                  {
                                    staticClass: "table-modal-analiseOnly",
                                    attrs: {
                                      data: _vm.users,
                                      stripe: _vm.tableStripe
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var data = ref.data
                                            return [
                                              _c(
                                                "tbody",
                                                {
                                                  staticClass: "dataContainer"
                                                },
                                                _vm._l(data, function(
                                                  tr,
                                                  indextr
                                                ) {
                                                  return _c(
                                                    "vs-tr",
                                                    {
                                                      key: indextr,
                                                      attrs: { data: tr }
                                                    },
                                                    [
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          staticClass:
                                                            "td-nameAnalyse",
                                                          attrs: {
                                                            data: tr.name
                                                          }
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  tr.name
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ])
                                                        ]
                                                      ),
                                                      _c(
                                                        "vs-td",
                                                        {
                                                          staticClass:
                                                            "td-monitoringModal",
                                                          attrs: {
                                                            data: tr.monitoring
                                                          }
                                                        },
                                                        [
                                                          _c("vs-switch", {
                                                            attrs: {
                                                              value:
                                                                tr.monitoring,
                                                              id:
                                                                "monitoring" +
                                                                indextr
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.monitoringOne(
                                                                  tr.monitoring,
                                                                  tr.id,
                                                                  tr.protecting
                                                                )
                                                              }
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                }),
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      3764822149
                                    )
                                  },
                                  [
                                    _c(
                                      "template",
                                      { slot: "thead" },
                                      [
                                        _c(
                                          "vs-th",
                                          {
                                            staticClass: "th-nameAnalyse",
                                            attrs: { "sort-key": "name" }
                                          },
                                          [_vm._v("Cuenta")]
                                        ),
                                        _c(
                                          "vs-th",
                                          {
                                            staticClass: "th-monitoringModal",
                                            attrs: { "sort-key": "monitoring" }
                                          },
                                          [_vm._v("Analizar")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  2
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "btn-campaign-filter-container"
                                  },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "btn-campaign-filter",
                                        attrs: { variant: "outline-secondary" },
                                        on: { click: _vm.monitoringAllModal }
                                      },
                                      [_vm._v("Analizar todas")]
                                    )
                                  ],
                                  1
                                ),
                                !_vm.stepOneComplete
                                  ? _c(
                                      "span",
                                      { staticClass: "analyseError" },
                                      [
                                        _vm._v(
                                          "*Analiza al menos una cuenta para poder continuar"
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ])
                        : _c("div", [
                            !_vm.oneAccUser
                              ? _c(
                                  "div",
                                  { staticClass: "text-center-modal" },
                                  [
                                    _c("h5", [
                                      _vm._v(
                                        "Has iniciado un análisis gratuito de la calidad del tráfico en:"
                                      )
                                    ]),
                                    _vm._l(_vm.users, function(acc) {
                                      return _c("ul", { key: acc.name }, [
                                        acc.monitoring === true
                                          ? _c("li", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(acc.name) +
                                                  "\n                "
                                              )
                                            ])
                                          : _vm._e()
                                      ])
                                    }),
                                    _c("p", [
                                      _vm._v(
                                        "En los próximos días podrás ir viendo los resultados en las pestañas de Tráfico y Estadísticas."
                                      )
                                    ])
                                  ],
                                  2
                                )
                              : _c(
                                  "div",
                                  { staticClass: "text-center-modal" },
                                  [
                                    _c("h5", [
                                      _vm._v(
                                        "Has iniciado un análisis gratuito de la calidad del tráfico en " +
                                          _vm._s(_vm.users[0].name) +
                                          "."
                                      )
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        "En los próximos días podrás ir viendo los resultados en las pestañas de Tráfico y Estadísticas."
                                      )
                                    ])
                                  ]
                                ),
                            _c("p", { staticClass: "remember" }, [
                              _c("span", { staticClass: "rememberText" }, [
                                _vm._v("RECUERDA:")
                              ]),
                              _vm._v(" El modo análisis es "),
                              _c("strong", [_vm._v("completamente GRATIS")]),
                              _vm._v(" por tiempo ilimitado.")
                            ])
                          ])
                    ]
                  ),
                  _c(
                    "tab-content",
                    {
                      staticClass: "mb-5",
                      attrs: {
                        title: "Reglas de Negocio",
                        icon: "feather icon-filter"
                      }
                    },
                    [
                      !_vm.preStepThree
                        ? _c("div", [
                            _c("div", { staticClass: "text-center" }, [
                              _c("h2", [
                                _vm._v(
                                  "Ponle límite a tu coste de adquisición de clientes."
                                )
                              ]),
                              _c("h5", [
                                _vm._v("Las reglas de negocio "),
                                _c("strong", [_vm._v("limitan los clics")]),
                                _vm._v(" que puede hacer cada usuario en "),
                                _c("strong", [_vm._v("tus anuncios")]),
                                _vm._v(".")
                              ]),
                              _vm.oneAccUser
                                ? _c(
                                    "p",
                                    { staticClass: "text-center-modal" },
                                    [
                                      _vm._v(
                                        "Introduce la regla que quieras tener en cuenta para el análisis:"
                                      )
                                    ]
                                  )
                                : _c(
                                    "p",
                                    { staticClass: "text-center-modal" },
                                    [
                                      _vm._v(
                                        "Introduce las reglas que quieras tener en cuenta para el análisis:"
                                      )
                                    ]
                                  ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "vs-table",
                                    {
                                      staticClass: "table-modal-rules",
                                      attrs: {
                                        data: _vm.rulesModal,
                                        stripe: _vm.tableStripe
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var data = ref.data
                                              return [
                                                _c(
                                                  "tbody",
                                                  {
                                                    staticClass: "dataContainer"
                                                  },
                                                  _vm._l(data, function(
                                                    tr,
                                                    indextr
                                                  ) {
                                                    return _c(
                                                      "vs-tr",
                                                      {
                                                        key: indextr,
                                                        attrs: { data: tr }
                                                      },
                                                      [
                                                        _c(
                                                          "vs-td",
                                                          {
                                                            staticClass:
                                                              "td-nameRule",
                                                            attrs: {
                                                              data: tr.name
                                                            }
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    tr.name
                                                                  ) +
                                                                  "\n                          "
                                                              )
                                                            ])
                                                          ]
                                                        ),
                                                        _c(
                                                          "vs-td",
                                                          {
                                                            staticClass:
                                                              "td-limitModal",
                                                            attrs: {
                                                              data: tr.limit
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "rulesDataCon"
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    staticClass:
                                                                      "numberCont",
                                                                    attrs: {
                                                                      type:
                                                                        "number",
                                                                      min: "1",
                                                                      value: "1"
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        _vm.isRuleComplete
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        tr.limit,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          tr,
                                                                          "limit",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "tr.limit"
                                                                    }
                                                                  }
                                                                ),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "clic "
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "vs-td",
                                                          {
                                                            staticClass:
                                                              "td-timeModal",
                                                            attrs: {
                                                              data: tr.time
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "rulesDataCon"
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "rule-txt"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "en "
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    staticClass:
                                                                      "numberCont",
                                                                    attrs: {
                                                                      type:
                                                                        "number",
                                                                      min: "1",
                                                                      value:
                                                                        "24"
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        _vm.isRuleComplete
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        tr.time,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          tr,
                                                                          "time",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "tr.time"
                                                                    }
                                                                  }
                                                                ),
                                                                _c(
                                                                  "vs-select",
                                                                  {
                                                                    staticClass:
                                                                      "selectCont",
                                                                    attrs: {
                                                                      value:
                                                                        "hours"
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        _vm.isRuleComplete
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        tr.timeUnit,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          tr,
                                                                          "timeUnit",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "tr.timeUnit"
                                                                    }
                                                                  },
                                                                  _vm._l(
                                                                    _vm.timeType,
                                                                    function(
                                                                      timeitem,
                                                                      newindex
                                                                    ) {
                                                                      return _c(
                                                                        "vs-select-item",
                                                                        {
                                                                          key: newindex,
                                                                          attrs: {
                                                                            value:
                                                                              timeitem.type,
                                                                            text:
                                                                              timeitem.name
                                                                          }
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }),
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        691738570
                                      )
                                    },
                                    [
                                      _c(
                                        "template",
                                        { slot: "thead" },
                                        [
                                          _c(
                                            "vs-th",
                                            {
                                              staticClass: "th-nameRule",
                                              attrs: { "sort-key": "name" }
                                            },
                                            [_vm._v("Cuenta")]
                                          ),
                                          _c(
                                            "vs-th",
                                            {
                                              staticClass: "th-limitModal",
                                              attrs: { "sort-key": "limit" }
                                            },
                                            [_vm._v("Permitir hasta")]
                                          ),
                                          _c(
                                            "vs-th",
                                            {
                                              staticClass: "th-timeModal",
                                              attrs: { "sort-key": "timeUnit" }
                                            },
                                            [_vm._v("Tiempo")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    2
                                  ),
                                  _vm.errorRule
                                    ? _c(
                                        "div",
                                        { staticClass: "analyseError" },
                                        [
                                          _c("p", [
                                            _vm._v(
                                              "*Debes añadir al menos una regla en alguna cuenta."
                                            )
                                          ])
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ])
                          ])
                        : _c("div", [
                            !_vm.oneAccUser
                              ? _c(
                                  "div",
                                  { staticClass: "text-center-modal" },
                                  [
                                    _c("h5", [
                                      _vm._v(
                                        "Has añadido una regla de negocio para el análisis en:"
                                      )
                                    ]),
                                    _vm._l(_vm.rulesModal, function(ruleAcc) {
                                      return _c("ul", { key: ruleAcc.name }, [
                                        ruleAcc.limit !== ""
                                          ? _c("li", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(ruleAcc.name) +
                                                  "\n                "
                                              )
                                            ])
                                          : _vm._e()
                                      ])
                                    }),
                                    _c("p", [
                                      _vm._v(
                                        "Puedes modificar las reglas o añadir nuevas en la pestaña Reglas de Negocio."
                                      )
                                    ])
                                  ],
                                  2
                                )
                              : _c(
                                  "div",
                                  { staticClass: "text-center-modal" },
                                  [
                                    _c("h5", [
                                      _vm._v(
                                        "Has añadido una regla de negocio para el análisis en " +
                                          _vm._s(_vm.rulesModal[0].name) +
                                          "."
                                      )
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        "Puedes modificar las reglas o añadir nuevas en la pestaña Reglas de Negocio."
                                      )
                                    ])
                                  ]
                                ),
                            _c("p", { staticClass: "remember" }, [
                              _c("span", { staticClass: "rememberText" }, [
                                _vm._v("RECUERDA:")
                              ]),
                              _vm._v(
                                " La regla solo servirá para el análisis y si activas el "
                              ),
                              _c("strong", [_vm._v("modo protección")]),
                              _vm._v(" limitará los clics de manera efectiva.")
                            ])
                          ])
                    ]
                  ),
                  _c(
                    "tab-content",
                    {
                      staticClass: "mb-5",
                      attrs: {
                        title: "Código de Seguimiento",
                        icon: "feather icon-code"
                      }
                    },
                    [
                      _c("div", { staticClass: "text-center-modal" }, [
                        _c("h2", [_vm._v("Instala el código de seguimiento:")]),
                        _vm.oneAccUser
                          ? _c("p", [
                              _vm._v("Para "),
                              _c("strong", [
                                _vm._v(
                                  "multiplicar el rendimiento en tus anuncios"
                                )
                              ]),
                              _vm._v(
                                " del algoritmo, te recomendamos que instales el código de seguimiento en cada página del dominio que tenga anuncios vinculados."
                              )
                            ])
                          : _c("p", [
                              _vm._v("Para "),
                              _c("strong", [
                                _vm._v(
                                  "multiplicar el rendimiento en tus anuncios"
                                )
                              ]),
                              _vm._v(
                                " del algoritmo, te recomendamos que instales el código de seguimiento en cada página de los dominios que tengan anuncios vinculados."
                              )
                            ]),
                        _c("p", [
                          _vm._v(
                            "Puedes hacerlo a través de las siguientes opciones:"
                          )
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "accordion",
                            attrs: { role: "tablist" }
                          },
                          [
                            _c(
                              "b-card",
                              { staticClass: "mb-1", attrs: { "no-body": "" } },
                              [
                                _c(
                                  "b-card-header",
                                  {
                                    staticClass: "p-1",
                                    attrs: {
                                      "header-tag": "header",
                                      role: "tab"
                                    }
                                  },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "b-toggle",
                                            rawName:
                                              "v-b-toggle.accordionModal-1",
                                            modifiers: {
                                              "accordionModal-1": true
                                            }
                                          }
                                        ],
                                        staticClass: "paintAccordion",
                                        attrs: { block: "" }
                                      },
                                      [
                                        _vm._v(
                                          "Google Tag Manager (Recomendado)"
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-collapse",
                                  {
                                    attrs: {
                                      id: "accordionModal-1",
                                      visible: "",
                                      accordion: "accordionModal",
                                      role: "tabpanel"
                                    }
                                  },
                                  [
                                    _c(
                                      "b-card-body",
                                      [
                                        _c("b-card-text", [
                                          _vm._v(
                                            "Accede a nuestro manual a través del siguiente enlace:"
                                          )
                                        ]),
                                        _c("b-card-text", [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href:
                                                  "https://www.clickdefense.io/gtm-manual.html",
                                                target: "_blank"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "https://www.clickdefense.io/gtm-manual.html"
                                              )
                                            ]
                                          )
                                        ]),
                                        _c("b-card-text", [
                                          _vm._v(
                                            "Sigue los pasos para configurar el pixel."
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-card",
                              { staticClass: "mb-1", attrs: { "no-body": "" } },
                              [
                                _c(
                                  "b-card-header",
                                  {
                                    staticClass: "p-1",
                                    attrs: {
                                      "header-tag": "header",
                                      role: "tab"
                                    }
                                  },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "b-toggle",
                                            rawName:
                                              "v-b-toggle.accordionModal-4",
                                            modifiers: {
                                              "accordionModal-4": true
                                            }
                                          }
                                        ],
                                        staticClass: "paintAccordion",
                                        attrs: { block: "" }
                                      },
                                      [_vm._v("Otras opciones")]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-collapse",
                                  {
                                    attrs: {
                                      id: "accordionModal-4",
                                      accordion: "accordionModal",
                                      role: "tabpanel"
                                    }
                                  },
                                  [
                                    _c(
                                      "b-card-body",
                                      [
                                        _c("b-card-text", [
                                          _vm._v(
                                            "\n                      Para instalar el pixel a través de otras opciones, pongase en contacto con soporte@clickdefense.io.\n                      ¡Muchas gracias!\n                    "
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c("p", { staticClass: "remember" }, [
                              _c("span", { staticClass: "rememberText" }, [
                                _vm._v("RECUERDA:")
                              ]),
                              _vm._v(
                                " Este paso es opcional y puedes completarlo mas adelante."
                              )
                            ])
                          ],
                          1
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }